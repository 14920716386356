import handlerDataSearch from '@/handlers/handlerDataSearch'
import axios from '@axios'

export default {
  namespaced: true,
  state: { abortController: null },
  getters: {},
  mutations: {
    SET_CONTROLLER(state, controller) {
      state.abortController = controller
    },
    CLEAR_CONTROLLER(state) {
      state.abortController = null
    },
  },
  actions: {
    fetch_holidays(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/avenir_holiday', {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements({ commit, state }, params) {
      if (state.abortController) {
        state.abortController.abort()
        commit('CLEAR_CONTROLLER')
      }
      const controller = new AbortController()
      commit('SET_CONTROLLER', controller)

      const currentParams = handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/medical_order/auth_pending', { params: currentParams, signal: controller.signal })
          .then(response => {
            commit('CLEAR_CONTROLLER')
            resolve(response)
          })
          .catch(error => {
            if (error.name === 'AbortError' || error.message === 'canceled') {
              console.log('Petición abortada por el usuario')
              resolve([])
            } else {
              console.log('Error en la petición', error.message)
              reject(error)
            }
            commit('CLEAR_CONTROLLER')
          })
      })
    },
    fetch_excel_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order/auth_pending`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/medical_order/auth_pending/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: { perPage: 500, page: 1, companyType: 'ARL' }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_company(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarter(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter_by_id/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_service_bundles(ctx, { arl_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/service_bundle/by_arl/${arl_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type, deep, parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, {
            params: { deep: deep, parent: parent }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_headquarters(ctx, { company_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company/headquarter/${company_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_companies(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/company`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value_item(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 1 }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 2 }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, { parent }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', {
            params: { deep: 3, parent: parent }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/v1/cases/medical_order/auth_pending/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
